@import './variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap';

body {
  font-size: .875rem;
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }
