.addQuestionButton {
  margin-top: 20px;
  margin-bottom: 40px;
}

.questionTitleFormField {
  font-weight: bold;
}

.questionFormBlock {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
  margin: 20px 0;
}

.variantFormBLock {
  margin: 10px 0;
}